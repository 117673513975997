/**
 * Copyright (C) 2016 Maxime Petazzoni <maxime.petazzoni@bulix.org>.
 * All rights reserved.
 * 
 * Slightly modified to parse SSE data line-by-line.
 */

var SSE = function(url, options) {
  if (!(this instanceof SSE)) {
    return new SSE(url, options);
  }

  // -- Constants --
  this.INITIALIZING = -1;
  this.CONNECTING = 0;
  this.OPEN = 1;
  this.CLOSED = 2;

  // -- Config --
  this.url = url;
  options = options || {};
  this.headers = options.headers || {};
  this.payload = options.payload !== undefined ? options.payload : '';
  this.method = options.method || (this.payload && 'POST' || 'GET');
  this.withCredentials = !!options.withCredentials;

  // -- Internal fields --
  this.FIELD_SEPARATOR = ':';
  this.listeners = {};
  this.xhr = null;
  this.readyState = this.INITIALIZING;

  // These track streamed data:
  this.progress = 0;      // How many bytes we've processed so far
  this.buffer = '';       // Accumulate raw data here before splitting into lines
  this.eventLines = [];   // Accumulate lines for the current SSE event block

  // -- Event handling methods --
  this.addEventListener = function(type, listener) {
    if (this.listeners[type] === undefined) {
      this.listeners[type] = [];
    }
    if (this.listeners[type].indexOf(listener) === -1) {
      this.listeners[type].push(listener);
    }
  };

  this.removeEventListener = function(type, listener) {
    if (this.listeners[type] === undefined) {
      return;
    }
    var filtered = [];
    this.listeners[type].forEach(function(element) {
      if (element !== listener) {
        filtered.push(element);
      }
    });
    if (filtered.length === 0) {
      delete this.listeners[type];
    } else {
      this.listeners[type] = filtered;
    }
  };

  this.dispatchEvent = function(e) {
    if (!e) {
      return true;
    }
    e.source = this;

    var onHandler = 'on' + e.type;
    if (this.hasOwnProperty(onHandler)) {
      this[onHandler].call(this, e);
      if (e.defaultPrevented) {
        return false;
      }
    }
    if (this.listeners[e.type]) {
      return this.listeners[e.type].every(function(callback) {
        callback(e);
        return !e.defaultPrevented;
      });
    }
    return true;
  };

  this._setReadyState = function(state) {
    var event = new CustomEvent('readystatechange');
    event.readyState = state;
    this.readyState = state;
    this.dispatchEvent(event);
  };

  // -- Error and abort handlers --
  this._onStreamFailure = function(e, status, responseText) {
    var event = new CustomEvent('error');
    event.data = {
      status: status,
      event: e,
      responseText: responseText,
    };
    this.dispatchEvent(event);
    this.close();
  };

  this._onStreamAbort = function(e) {
    this.dispatchEvent(new CustomEvent('abort'));
    this.close();
  };

  // -- Main streaming logic (line-by-line) --
  this._onStreamProgress = function(e) {
    if (!this.xhr) {
      return;
    }

    // Check status
    if (this.xhr.status !== 200) {
      this._onStreamFailure(e, this.xhr.status, this.xhr.responseText);
      return;
    }

    if (this.readyState === this.CONNECTING) {
      this.dispatchEvent(new CustomEvent('open'));
      this._setReadyState(this.OPEN);
    }

    var newData = this.xhr.responseText.substring(this.progress);
    this.progress += newData.length;

    // Accumulate raw data into 'buffer'
    this.buffer += newData;
    var lines = this.buffer.split(/\r\n|\n|\r/);
    this.buffer = lines.pop(); // The last chunk might be incomplete

    lines.forEach(function(line) {
      if (!line.trim()) {
        // A blank line -> we've reached the end of the event block
        var chunk = this.eventLines.join('\n');
        this.eventLines = [];
        var evt = this._parseEventChunk(chunk);
        if (evt) {
          this.dispatchEvent(evt);
        }
      } else {
        this.eventLines.push(line);
      }
    }.bind(this));
  };

  this._onStreamLoaded = function(e) {
    this._onStreamProgress(e);

    if (this.eventLines.length > 0) {
      var chunk = this.eventLines.join('\n');
      this.eventLines = [];
      var evt = this._parseEventChunk(chunk);
      if (evt) {
        this.dispatchEvent(evt);
      }
    }
    this._checkStreamClosed();
  };

  this._checkStreamClosed = function() {
    if (!this.xhr) {
      return;
    }
    if (this.xhr.readyState === XMLHttpRequest.DONE) {
      this._setReadyState(this.CLOSED);
    }
  };

  /**
   * Parse a received SSE event chunk into a CustomEvent object.
   * This chunk is typically the concatenation of lines preceding a blank line.
   */
  this._parseEventChunk = function(chunk) {
    if (!chunk || chunk.length === 0) {
      return null;
    }

    var e = {
      id: null,
      retry: null,
      data: '',
      event: 'message', // default type is 'message'
    };

    // Split chunk by single newlines and parse
    chunk.split(/\r?\n/).forEach(function(line) {
      var trimmedLine = line.trimRight();
      if (!trimmedLine) return; // skip empty/comment lines

      var index = trimmedLine.indexOf(this.FIELD_SEPARATOR);
      if (index <= 0) {
        // If no colon found (or starts with colon) -> comment or invalid line
        return;
      }

      var field = trimmedLine.substring(0, index).trim();
      var value = trimmedLine.substring(index + 1).trimLeft();

      // Recognize SSE fields
      if (field === 'data') {
        // data: lines can appear multiple times
        e.data += (e.data ? '\n' : '') + value;
      } else if (field === 'event') {
        e.event = value;
      } else if (field === 'id') {
        e.id = value;
      } else if (field === 'retry') {
        e.retry = value;
      }
      // ignore unknown fields
    }.bind(this));

    // Construct a CustomEvent from the final fields
    var evt = new CustomEvent(e.event);
    evt.data = e.data;
    evt.id = e.id;
    return evt;
  };

  // -- Public API: start & close the connection --

  this.stream = function() {
    this._setReadyState(this.CONNECTING);

    this.xhr = new XMLHttpRequest();
    this.xhr.addEventListener('progress', this._onStreamProgress.bind(this));
    this.xhr.addEventListener('load', this._onStreamLoaded.bind(this));
    this.xhr.addEventListener('readystatechange', this._checkStreamClosed.bind(this));
    this.xhr.addEventListener('error', this._onStreamFailure.bind(this));
    this.xhr.addEventListener('abort', this._onStreamAbort.bind(this));
    this.xhr.open(this.method, this.url);
    for (var header in this.headers) {
      this.xhr.setRequestHeader(header, this.headers[header]);
    }
    this.xhr.withCredentials = this.withCredentials;
    this.xhr.send(this.payload);
  };

  this.close = function() {
    if (this.readyState === this.CLOSED) {
      return;
    }
    this.xhr.abort();
    this.xhr = null;
    this._setReadyState(this.CLOSED);
  };
};

if (typeof exports !== 'undefined') {
  exports.SSE = SSE;
}
