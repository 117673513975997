import Theme from './shared/Theme';
import { ThemeProvider } from 'styled-components';
import Playground from './pages/Playground';
import { ChatControlProvider } from './shared/context/ChatControlContext';
import Login from './pages/Login';
import CheckoutRedirect from './pages/CheckoutRedirect';
import { Auth0Provider } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={Theme}> 
      <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: window.location.origin + "/auth",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
        scope: "chat:messages profile email",
      }}
      >
        <ChatControlProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Playground/>}/>
              <Route path="/auth" element={<Login />}/>
              <Route path="/checkout/local/sabia2-small" element={<CheckoutRedirect model_variant='small'/>}/>
              <Route path="/checkout/local/sabia2-medium" element={<CheckoutRedirect model_variant='medium'/>}/>
            </Routes>
          </Router>
        </ChatControlProvider>
      </Auth0Provider>

    </ThemeProvider>
  );
}

export default App;
