import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import styled from 'styled-components';

interface Reference {
  title: string;
  link: string;
  snippet: string;
  question: string;
}

const RefButton = ({ references }: { references: Reference[] }) => {
  const [showReferences, setShowReferences] = useState(false);
  const groupedReferences = references.reduce((acc: Record<string, Reference[]>, ref) => {
    if (!acc[ref.question]) {
      acc[ref.question] = [];
    }
    acc[ref.question].push(ref);
    return acc;
  }, {});

  if (!references || references.length === 0) return null;

  return (
    <ReferencesWrapper>
      <StyledButton onClick={() => setShowReferences(true)}>
        Referências
      </StyledButton>

      <StyledDialog
        open={showReferences}
        onClose={() => setShowReferences(false)}
        fullWidth
        maxWidth="md"
      >
        <StyledDialogTitle>Referências</StyledDialogTitle>
        <StyledDialogContent dividers>
          {Object.entries(groupedReferences).map(([question, refs]) => (
            <QuestionBlock key={question}>
              <h3>{question}</h3>
              <ul>
                {refs.map((ref, idx) => (
                  <li key={idx}>
                    <a href={ref.link} target="_blank" rel="noreferrer">
                      {ref.title || ref.link}
                    </a>
                    {ref.snippet && <SnippetText>{ref.snippet}</SnippetText>}
                  </li>
                ))}
              </ul>
            </QuestionBlock>
          ))}
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={() => setShowReferences(false)}>
            Fechar
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </ReferencesWrapper>
  );
};

export default RefButton;

const ReferencesWrapper = styled.div`
  margin: 10px 0;
  text-align: left;
`;

const QuestionBlock = styled.div`
  margin-bottom: 1.5rem;
  h3 {
    margin: 0.75rem 0;
  }
  ul {
    padding-left: 1.25rem;
    margin: 0;
  }
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: #2a62ff;
    text-decoration: underline;
  }
`;

const SnippetText = styled.p`
  margin: 0.25rem 0 0;
  font-size: 0.85rem;
  color: #555;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color:rgb(41, 43, 51);
  font-size: 0.8rem;
  font-weight: 600;
  border: 1px solid rgb(134, 133, 133);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color:rgb(238, 238, 238);
  }
  &:focus {
    outline: none;
  }
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 8px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-weight: bold;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: #fafafa;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 8px 24px;
`;
